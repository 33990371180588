<template>
  <div id="page-user-list">
    <div class="flex flex-wrap items-center">
      <!-- ITEMS PER PAGE -->
      <div class="flex-grow">
        <div class="vx-row">
          <div class="vx-col w-full"></div>
        </div>
        <br />

        <vx-card>
          <div class="export-table">
            <vs-table pagination max-items="20" :data="topup_wd" search>
              <template slot="header">
                <h3>ประวัติฝาก-ถอน 2 เดือนล่าสุด ({{ this.$route.params.username }})</h3>
              </template>

              <template slot="thead">
                <vs-th sort-key="datetime">วัน/เวลา</vs-th>
                <vs-th sort-key="data_type"> ฝาก/ถอน</vs-th>
                <vs-th sort-key="type"> ช่องทาง</vs-th>
                <vs-th sort-key="amount"> จำนวนเงิน </vs-th>
                <vs-th sort-key="before"> ก่อน </vs-th>
                <vs-th sort-key="credit"> เครดิต </vs-th>
                <vs-th sort-key="after"> หลัง </vs-th>
                <vs-th sort-key="info"> รายละเอียด </vs-th>
                <vs-th sort-key="used_datetime"> วัน/เวลา ดำเนินการ </vs-th>
                <vs-th sort-key="viewslip"> ดูสลิป </vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <!-- <vs-button radius color="primary" type="flat"   @click="gotomember(data[indextr].member_username)">{{ data[indextr].member_username}}</vs-button> -->
                  <vs-td>{{ data[indextr].datetime }}</vs-td>
                  <vs-td
                    :class="
                      tr.data_type == 'ฝาก'
                        ? 'text-success'
                        : tr.data_type == 'ถอน'
                        ? 'text-danger'
                        : null
                    "
                  >
                    {{ data[indextr].data_type }}
                    <span
                      v-if="tr.data_type == 'ถอน'"
                      :class="
                        tr.status == 1
                          ? 'text-success'
                          : tr.status == 2 || tr.status == 3
                          ? 'text-warning'
                          : tr.status == 4
                          ? 'text-danger'
                          : null
                      "
                    >
                      ({{ chk_status_wd(data[indextr].status) }})
                    </span>
                  </vs-td>
                  <vs-td>{{ data[indextr].type }}</vs-td>
                  <vs-td
                    :class="
                      tr.data_type == 'ฝาก'
                        ? 'text-success'
                        : tr.data_type == 'ถอน'
                        ? 'text-danger'
                        : null
                    "
                  >
                    {{ currency(parseFloat(data[indextr].amount).toFixed(2)) }}</vs-td
                  >
                  <vs-td>{{ currency(parseFloat(data[indextr].before).toFixed(2)) }}</vs-td>
                  <vs-td>{{ currency(parseFloat(data[indextr].credit).toFixed(2)) }}</vs-td>
                  <vs-td>{{ currency(parseFloat(data[indextr].after).toFixed(2)) }}</vs-td>
                  <vs-td>{{ data[indextr].info }}</vs-td>
                  <vs-td>{{ data[indextr].used_datetime }}</vs-td>
                  <vs-td v-if="data[indextr].type == 'KBANK'" ><vs-button size="small" color="primary" type="border" icon-pack="feather" icon="icon-search" target :href="{ url: url + data[indextr].id + '.jpg' }" ></vs-button ></vs-td>
                  <vs-td v-else-if="data[indextr].type == 'SCB'" ><vs-button size="small" color="primary" type="border" icon-pack="feather" icon="icon-search" target :href="{ url: url + data[indextr].id + '.jpg' }" ></vs-button ></vs-td>
                  <vs-td v-else-if="data[indextr].type == 'BAY'" ><vs-button size="small" color="primary" type="border" icon-pack="feather" icon="icon-search" target :href="{ url: url + data[indextr].id + '.jpg' }" ></vs-button ></vs-td>
                  <vs-td v-else-if="data[indextr].type == 'Admin'" ><vs-button size="small" color="primary" type="border" icon-pack="feather" icon="icon-search" target :href="{ url: url + data[indextr].id + '.jpg' }" ></vs-button ></vs-td>
                  <vs-td v-else-if="data[indextr].type == 'Wallet'" ><vs-button size="small" color="primary" type="border" icon-pack="feather" icon="icon-search" target :href="{ url: url + data[indextr].id + '.jpg' }" ></vs-button ></vs-td>
                  <vs-td v-else-if="data[indextr].status == 3 && data[indextr].id != '-'" ><vs-button size="small" color="warning" type="border" icon-pack="feather" @click=" req_return( data[indextr].username, data[indextr].amount, data[indextr].id ) " >คืนเครดิต</vs-button ></vs-td >
                  <vs-td v-else></vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <!-- POPUP RETRUN CREDIT -->
            <vs-popup
              classContent="popup-example"
              title="กรุณากรอกหมายเหตุ คืนเครดิต"
              :active.sync="popup_return"
            >
              <br />
              <vx-input-group class="mb-base">
                <template slot="prepend">
                  <div class="prepend-text bg-primary">
                    <span>Info</span>
                  </div>
                </template>

                <vs-input placeholder="Info" v-model="info" />
              </vx-input-group>

              <vs-button
                @click="
                  returncredit(
                    withdrawdata.username,
                    withdrawdata.amount,
                    withdrawdata.wd_id
                  )
                "
                color="primary"
                type="filled"
                >ยืนยัน</vs-button
              >
            </vs-popup>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import axios from '../../../axios'
export default {
  components: {
    vSelect
  },
  data () {
    return {
      url: process.env.VUE_APP_PHOTO_URL,
      topup_wd: [],
      status_wd: '',
      popup_return: false,
      withdrawdata: {
        username: '',
        amount: '',
        bank_code: '',
        bank_number: '',
        wd_id: ''
      },
      info: ''
    }
  },
  async mounted () {
    await axios
      .get(`/member/topupwd/${this.$route.params.username}`)
      .then((response) => (this.topup_wd = response.data))
  },
  methods: {
    currency (amount) {
      //SET NULL OR NaN = 0
      if (!amount || amount === 'NaN') {
        amount = 0
      }
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return (amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        return `${(amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      }

    },
    chk_status_wd (status) {
      if (status === 0) {
        return 'กำลังรอทำรายการ'
      } else if (status === 1) {
        return 'ทำรายการสำเร็จ'
      } else if (status === 2) {
        return 'คืนเครดิต'
      } else if (status === 3) {
        return 'ยกเลิกการถอนเงิน'
      } else if (status === 4) {
        return 'ทำรายการไม่สำเร็จ'
      } else if (status === 5) {
        return 'ตัดเครดิต'
      }
    },
    req_return (username, amount, wd_id) {
      this.withdrawdata.username = username
      this.withdrawdata.amount = amount
      this.withdrawdata.wd_id = wd_id
      this.popup_return = true
    },
    async returncredit (username, amount, wd_id) {
      this.popup_return = false
      await axios
        .post('withdraw/returnwithdraw', {
          username,
          amount,
          wd_id,
          info: this.info
        })
        .then((response) => (this.status_wd = response.data))
      if (this.status_wd.status === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: 'คืนเครดิตสำเร็จ',
          text: this.status_wd.info
        })
        setTimeout(() => {
          window.location.reload()
        }, 1500)
        this.set_store()
        this.get_data()
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'คืนเครดิตไม่สำเร็จ',
          text: this.status_wd.info
        })
        this.clear_data()
      }
    }
  }
}
</script>

<style lang="scss" scooped>
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
